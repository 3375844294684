<template>
    <div></div>
</template>

<script>
export default {
    mounted() {
       localStorage.clear();
       window.location = "/?anim=rst";
    }
}
</script>